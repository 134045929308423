<template>
  <div>
    <v-row dense>
      <v-col cols="2" sm="3">
        <v-sheet class="text mt-6 ml-1">{{ label }}</v-sheet>
      </v-col>
      <v-col cols="2" :data-cy="name + '_x_col'"
        ><v-text-field
          :data-cy="name + '_x'"
          label="x"
          type="number"
          :rules="required === true ? req : []"
          v-model.number="localValue[0]"
          @input="localValue = [parseFloat($event), value[1], value[2]]"
          :messages="[hints[0]]"
        ></v-text-field
      ></v-col>
      <v-col cols="2" :data-cy="name + '_y_col'"
        ><v-text-field
          :data-cy="name + '_y'"
          label="y"
          type="number"
          :rules="required === true ? req : []"
          v-model.number="localValue[1]"
          @input="localValue = [value[0], parseFloat($event), value[2]]"
          :messages="[hints[1]]"
        ></v-text-field
      ></v-col>
      <v-col cols="2" :data-cy="name + '_z_col'"
        ><v-text-field
          :data-cy="name + '_z'"
          label="z"
          type="number"
          :rules="required === true ? req : []"
          v-model.number="localValue[2]"
          @input="localValue = [value[0], value[1], parseFloat($event)]"
          :messages="[hints[2]]"
        ></v-text-field
      ></v-col>
      <v-col md="1" sm="2">
        <v-sheet :data-cy="name + '_unit'" class="text mt-6">{{
          unit
        }}</v-sheet>
      </v-col>
      <v-col cols="1">
        <DynamicInfoDialog :info="doc" :headerTitle="label"></DynamicInfoDialog>
      </v-col>
    </v-row>
    <v-row>
      <span
        :data-cy="name + '_hint'"
        class="text text-caption font-weight-light mt-n9 ml-4"
        >{{ hint }}</span
      >
    </v-row>
  </div>
</template>

<script>
const DynamicInfoDialog = () => import('./DynamicInfoDialog.vue')
import { cloneDeep } from 'lodash'
import { mapMutations } from 'vuex'

export default {
  components: { DynamicInfoDialog },
  data: () => ({
    req: [(v) => !!v || v === 0 || 'required'],
  }),

  props: {
    value: [Array, String],
    required: Boolean,
    label: String,
    name: String,
    doc: String,
    unit: String,
    hints: Array,
    hint: String,
    valuePath: Array,
    isNestedFormMultiple: Boolean,
  },

  methods: {
    ...mapMutations('dynamicForms', [
      'setMultipleValue',
      'setValue',
      'setDictValue',
    ]),
  },

  computed: {
    localValue: {
      get: function () {
        return this.value
      },
      set: function (value) {
        let path = cloneDeep(this.valuePath)
        if (this.isNestedFormMultiple) {
          this.$emit('update:value', this.setMultipleValue([path, value]))
        } else if (path.length > 1) {
          this.$emit('update:value', this.setDictValue([path, value]))
        } else {
          this.$emit('update:value', this.setValue([path, value]))
        }
      },
    },
  },
}
</script>
